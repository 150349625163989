import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Modal } from '@/sharedUI';

const CreateReferralContactModalContent = dynamic(
  () => import('./CreateReferralContactModalContent'),
);

const CreateReferralContactModal = () => {
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (router?.query?.c) {
      setIsOpen(true);
    }
  }, [router?.query?.c]);

  const onClose = () => setIsOpen(false);

  return (
    <Modal
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
    >
      <CreateReferralContactModalContent onClose={onClose} />
    </Modal>
  );
};

export default CreateReferralContactModal;
