import { FC } from 'react';
import { CreateReferralContactModal } from '@/widgets/CreateReferralContactModal';
import { ReviewRequestModal } from '@/widgets/ReviewRequestModal';
import { SendReferralMessageModal } from '@/widgets/SendReferralMessageModal';
import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';
import { blocksListSelector, configModel } from '@/entities/Config';
import { Layout } from '@/sharedUI';
import { MetaDecorator } from '@/sharedLib';
import { mapKeyToBlockComponents } from '../lib/templateMaps';

const Template: FC = () => {
  const blocksList = configModel.useStore(blocksListSelector);

  return (
    <Layout.Page
      key='pageLayout'
      footer={<Footer index={null} />}
      header={<Header index={null} />}
    >
      <SendReferralMessageModal />
      <CreateReferralContactModal />
      <ReviewRequestModal />
      <MetaDecorator />
      {blocksList?.map(({ key, index }, position: number) => {
        const Block = mapKeyToBlockComponents[key];

        const isOdd = position % 2 === 1;

        if (!Block) return null;

        return (
          <Block
            key={key + position}
            index={index ?? null}
            isOdd={isOdd}
          />
        );
      })}
    </Layout.Page>
  );
};

export default Template;
