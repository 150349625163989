import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { companyNameSelector, configModel } from '@/entities/Config';
import { Modal } from '@/sharedUI';

const ReviewRequestModalContent = dynamic(() => import('./ReviewRequestModalContent'));

const ReviewRequestModal = () => {
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (router?.query?.req) {
      setIsOpen(true);
    }
  }, [router?.query?.req]);

  const onClose = () => setIsOpen(false);

  const companyName = configModel.useStore(companyNameSelector);

  return (
    <Modal
      header={companyName || ''}
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
    >
      <ReviewRequestModalContent onClose={onClose} />
    </Modal>
  );
};

export default ReviewRequestModal;
